import React from 'react'
import { IconsProps } from 'msp-integrations'

const VideoExpandIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 17 17'
    >
      <g fill="none"><path fill="#FFF" d="M5.5 0v1H1v4.5H0V0zM1 11.5V16h4.5v1H0v-5.5zm16 0V17h-5.5v-1H16v-4.5zM17 0v5.5h-1V1h-4.5V0z" /><path stroke="#FFF" d="M5.5 5.5h6v6h-6z" /></g>
    </svg>
  )
}

export default VideoExpandIcon
